.shares-count {
  float: left;
  width: 20%;
  font-size: 16px;

  .shares-count-header {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 38px;
    letter-spacing: -1px !important;
    font-family: "Source Sans Pro", sans-serif !important;
    font-weight: 900 !important;
  }

  .shares-count-label {
    margin-top: 0;
    color: #9b9b9b;
    font-size: 16px;
  }
}

.shares-social {
  float: left;
  width: 60%;
  margin-top: 24px;

  a {
    color: #fff;
    border-radius: 4px;
    padding: 12px 8px;
    font-size: 0.8em;
    overflow: hidden;
    width: 100%;
    float: left;
    text-align: center;
    box-sizing: border-box;
  }

  .shares-new {
    float: left;
    width: 150px;
    margin-right: 2.5%;
    overflow: hidden;
    border-radius: 50px;
  }

  .shares-facebook {
    background: #3b5999;
    border-color: #3b5999;
  }

  .shares-twitter {
    background: #55acee;
    border-color: #55acee;
  }
}

@media (max-width: 768px) {
  .shares {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    .shares-count {
      justify-content: center;
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 0;
      margin-top: 10px;

      .shares-count-header {
        margin-top: 0;
        margin-right: 14px;
      }

      .shares-count-label {
        margin-bottom: 0;
      }
    }

    .shares-social {
      width: 100%;
      display: flex;
      margin-top: 0;
    }
  }

  .shares-count .shares-count-label {
    font-size: 14px;
  }

  .shareBar {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    .shareBar__share-count {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;

      .SocialMediaShareCount {
        margin-right: 14px;
      }
    }

    .shareBar__items {
      margin-left: 0;
    }
  }

  .mobile-hide {
    display: none !important;
  }
}

@media (max-width: 520px) {
  .shares-social {
    width: 80%;
  }

  .shares-count-header {
    font-size: 24px;
  }
}
