@import "../../node_modules/csstyle/csstyle";
@import "./settings/variables";
@import "./mixins";
@import "./grid";
@import "./flex";
@import "./typography";
@import "./spacing";
@import "./tailwind_migration";

@each $abbr, $name in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
  @for $i from 0 through 6 {
    .p#{$abbr}-#{$i} {
      padding-#{$name}: 1rem * $i !important;
    }
    .m#{$abbr}-#{$i} {
      margin-#{$name}: 1rem * $i !important;
    }
    .my-#{$i} {
      margin-top: 1rem * $i !important;
      margin-bottom: 1rem * $i !important;
    }
    .p-#{$i} {
      padding: 1rem * $i !important;
    }
    .py-#{$i} {
      padding-top: 1rem * $i !important;
      padding-bottom: 1rem * $i !important;
    }
    .m-#{$i} {
      margin: 1rem * $i !important;
    }
  }
}
