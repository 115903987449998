@import "../../base/helpers";

@include component(avatar) {
  // Variables ----------------------
  $avatar-size-xsmall: 28px !default;
  $avatar-size-xsmall-desktop: 28px !default;
  $avatar-size-small: 36px !default;
  $avatar-size-small-desktop: 56px !default;
  $avatar-size-medium: 60px !default;
  $avatar-size-medium-desktop: 120px !default;
  $avatar-size-large: 80px !default;
  $avatar-size-large-desktop: 150px !default;
  $avatar-campaignPage: 42px;
  $default-avatar-clr: $clr_backgroundLightBlue;
  $default-avatar-outline-clr: #93d1ef;

  // Component ----------------------
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  vertical-align: middle;
  width: 100%;

  @include option(no-image) {
    background-color: #d8d8d8;
    position: relative;

    > .icon {
      position: absolute;
      width: 50%;
      height: 50%;
      top: 0;
      left: 0;
      transform: translate(50%, 50%);
    }
  }

  @include option(is-team) {
    > .icon {
      position: absolute;
      width: 75%;
      height: 75%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @include option(v2) {
    background-color: $default-avatar-clr;
    margin-top: 0;
  }

  // Parts ----------------------
  @include part(image) {
    display: block;
    height: 0;
    opacity: 0%;
    padding-bottom: 100%;
    width: 100%;
  }

  // Options - size ----------------------
  @include option(size-xsmall) {
    height: $avatar-size-xsmall;
    width: $avatar-size-xsmall;

    @include breakpoint(phablet) {
      height: $avatar-size-xsmall-desktop;
      width: $avatar-size-xsmall-desktop;
    }
  }

  @include option(size-small) {
    height: $avatar-size-small;
    width: $avatar-size-small;

    @include breakpoint(phablet) {
      height: $avatar-size-small-desktop;
      width: $avatar-size-small-desktop;
    }

    &.--v2 {
      height: $avatar-campaignPage;
      width: $avatar-campaignPage;
    }
  }

  @include option(size-medium) {
    height: $avatar-size-medium;
    width: $avatar-size-medium;

    @include breakpoint(phablet) {
      height: $avatar-size-medium-desktop;
      width: $avatar-size-medium-desktop;
    }
  }

  @include option(size-large) {
    height: $avatar-size-large;
    width: $avatar-size-large;

    @include breakpoint(phablet) {
      height: $avatar-size-large-desktop;
      width: $avatar-size-large-desktop;
    }
  }
}
