@import "./settings/grid";

.w-100 {
  width: 100%;
}

.padding-sm {
  padding: 1rem;
}

.padding-lg {
  padding: 4rem 2rem;
}
