@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"), "url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGIq-Fb0zf838trI74uojZQY.woff2)"format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
