// Color Map for Campaigns ----------------------

$campaignCategoryColors: (
  default: #371c7e,
  social: #9230c0,
  welfare: #ef1c25,
  development: #ffb200,
  environment: #67c401,
  refugee: #e233a0,
  community: #0160a0,
  health: #00abf0,
  politics: #ff7500,
);

// Colors

$clr_brandPrimary: #1fa8df;
$clr_brandPrimaryLight: #bae3f4;
$clr_brandPrimaryActive: #0160a0;
$clr_brandSecondary: #e444a0;
$clr_brandTertiary: #ffb202;
$clr_text: #333;
$clr_textInverse: #fff;
$clr_textMeta: #aeaeae;
$clr_textSubdued: lighten($clr_text, 30%);
$clr_heading: $clr_brandPrimary;
$clr_borderColor: lighten($clr_textSubdued, 30%);
$clr_error: #d60000;
$clr_success: #0b996c;
$clr_lightBgGrey: #fafafa;
$clr_lightGrey: #f2f2f2;
$clr_lightMidGrey: #d7d7d7;
$clr_midGrey: #999;
$clr_darkGrey: #5d5d5d;
$clr_backgroundLightBlue: #e8f6fc;

// Font settings ----------------------
$font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif;
$font_familyCopy: "Open Sans", arial, sans-serif;
$font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif;

// Header settings ----------------------
$header-height-mobile: 62px;
$header-height-desktop: 68px;

// Inputs ----------------------

$inputs_paddingVertical: 0.75rem;
$inputs_paddingHorizontal: 1rem;
$inputs_fontSize: 1rem;
$inputs_borderRadius: 4px;
$inputs_borderWidth: 1px;
$inputs_borderColor: lighten($clr_textSubdued, 30%);
$inputs_color: #000;
$inputs_errorColor: red;

// Box

$box_backgroundColor: rgba(238, 238, 238, 29%);
$box_borderColor: transparent;
$box_borderRadius: 4px;
$box_iconSize: 0.5rem;
$box_fontSize: 0.8rem;
$box_HeadingFontSize: 0.9rem;
$box_padding: 1rem;
$box_IconBackgroundColor: #ffb200;
$box_IconBorderColor: transparent;
$box_IconColor: white;

// Grid settings ----------------------

$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-breakpoints: sm 37.5em,
  md 50em,
  lg 64em,
  xl 70em;
$grid-gutter-compensation: $grid-gutter-width * 0.5 * -1;
$grid-half-gutter-width: $grid-gutter-width * 0.5;

// Timing ----------------------
$timing_default: 0.4s;
$timing_fast: 0.2s;
$timing_slow: 0.8s;

// Avatar sizes ----------------------
$avatar-size-xsmall: 28px;
$avatar-size-xsmall-desktop: 28px;
$avatar-size-small: 36px;
$avatar-size-small-desktop: 56px;
$avatar-size-medium: 60px;
$avatar-size-medium-desktop: 120px;
$avatar-size-large: 80px;
$avatar-size-large-desktop: 150px;
