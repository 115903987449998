@import "../../base/helpers";

@include component(header) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $timing_fast: 0.2s !default;
  $header-height-mobile: 62px !default;
  $header-height-desktop: 68px !default;

  // Component ----------------------
  background-color: #fff;
  height: $header-height-mobile;
  position: relative;
  z-index: 201;

  @include breakpoint(tablet) {
    height: $header-height-desktop;
  }

  @include breakpoint(laptop) {
    padding-left: 24px;
  }

  // Parts ----------------------
  @include part(positioning) {
    box-shadow: 0 2px 4px 0 rgba(143, 141, 141, 0.5);

    @include option(fixed) {
      @include breakpoint(tablet) {
        background-color: #fff;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
      }
    }
  }

  @include part(container) {
    align-items: center;
    display: flex;
    height: $header-height-mobile;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    transition: height $timing_fast ease-in-out, padding $timing_fast ease-in-out;

    @include breakpoint(tablet) {
      height: $header-height-desktop;
      padding-right: 0;
    }

    @include breakpoint(laptop) {
      padding-left: 24px;
    }

    // Container for full-width version of header grows to a max-width, to
    // prevent things looking ridiculous on really wide screens
    @include breakpoint(xldesktop) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1680px;
    }

    @include option(contained) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1280px;
    }
  }

  @include part(section) {
    @include option(has-items) {
      align-items: center;
      display: flex;
    }

    @include option(grows-desktop) {
      @include breakpoint(tablet) {
        flex: 1 0 auto;
      }
    }
  }

  @include part(logo-link) {
    display: block;
    flex: 0 0 auto;
    width: 42px;
    transition: width $timing_fast ease-in-out;
  }

  @include part(svg-logo) {
    display: block;
    height: auto;
    width: 100%;
  }

  @include part(search-mobile) {
    align-items: center;
    display: flex;
    height: $header-height-mobile;
    padding: 0 12px;

    @include breakpoint(mobile) {
      padding: 0 16px;
    }

    @include breakpoint(tablet) {
      display: none;
    }
  }

  @include part(user) {
    align-items: center;
    background-color: #eee;
    display: flex;
    height: $header-height-mobile;
    padding: 0 12px;
    margin-right: 12px;

    @include breakpoint(mobile) {
      padding: 0 16px;
      margin-right: 16px;
    }

    @include breakpoint(tablet) {
      display: none;
    }
  }

  // Options ----------------------
}
