@mixin breakpoint($point) {
  @if $point==xldesktop {
    @media (min-width: 80em) {
      @content ;
    }
  }

  @if $point==desktop {
    @media (min-width: 70em) {
      @content ;
    }
  } @else if $point==laptop {
    @media (min-width: 64em) {
      @content ;
    }
  } @else if $point==tablet {
    @media (min-width: 50em) {
      @content ;
    }
  } @else if $point==phablet {
    @media (min-width: 37.5em) {
      @content ;
    }
  } @else if $point==mobile {
    @media (min-width: 25em) {
      @content ;
    }
  } @else if $point==mobileonly {
    @media (max-width: 37.5em) {
      @content ;
    }
  } @else {
    @media (max-width: $point) {
      @content ;
    }
  }
}

@mixin container() {
  $grid-gutter-width: 30px !default;
  $grid-half-gutter-width: $grid-gutter-width * 0.5 !default;

  margin-left: auto;
  margin-right: auto;
  max-width: 1170px;
  padding-left: $grid-half-gutter-width;
  padding-right: $grid-half-gutter-width;
  width: 100%;
}

@mixin container-narrow() {
  $grid-gutter-width: 30px !default;
  $grid-half-gutter-width: $grid-gutter-width * 0.5 !default;

  padding-left: $grid-half-gutter-width;
  padding-right: $grid-half-gutter-width;
  width: 100%;

  @include breakpoint(phablet) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include breakpoint(tablet) {
    margin-left: auto;
    margin-right: auto;
    max-width: 860px;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@mixin shadowMe() {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
}

@mixin hideText() {
  display: inline-block;
  text-indent: -9999px;
}

@mixin visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

@mixin silentFocus() {
  &:focus {
    outline: none;
  }
}

@mixin clearfix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin verticalSpacing($multiplier: 1, $tabletMultiplier: 1, $mobileMultiplier: 1) {
  $verticalSpacing: 10px !default;

  padding-top: $verticalSpacing * $multiplier;
  padding-bottom: $verticalSpacing * $multiplier;

  @include g-state(tablet) {
    padding-top: ($verticalSpacing * $tabletMultiplier);
    padding-bottom: ($verticalSpacing * $tabletMultiplier);
  }

  @include g-state(mobile) {
    padding-top: ($verticalSpacing * $mobileMultiplier);
    padding-bottom: ($verticalSpacing * $mobileMultiplier);
  }
}

@mixin widget($background-color) {
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  border: 1px solid $clr_lightMidGrey;
  padding: 1rem;
  margin: 1rem 0;
}

@mixin well($border-color) {
  background-color: #fcfcfc;
  padding: 0.5rem 1rem;
  border: 1px solid $border-color;
  border-radius: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
}

@mixin cell($background-color) {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  background-color: $background-color;
  border-radius: 0.25rem;
  padding: 0.75rem;
}

@mixin resetFontSize() {
  html {
    font-size: 62.5% !important;
  } /* =10px */
  body {
    font-size: 1.2rem;
  } /* =12px */
}

@mixin resetButtonSizes() {
  font-size: 16px;

  button span,
  li span {
    font-size: 0.9em;

    &.has-text {
      font-size: 0.9em !important;
    }
  }
}
