@import "../../base/helpers";

@include component(navigation) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $timing_fast: 0.2s !default;
  $timing_slow: 0.8s !default;
  $header-height-mobile: 62px !default;
  $header-height-desktop: 68px !default;

  // Component ----------------------
  line-height: 1;

  @include breakpoint(tablet) {
    width: 100%;
  }

  // Parts ----------------------
  @include part(checkbox) {
    opacity: 0%;
    position: absolute;
    visibility: hidden;

    @include breakpoint(tablet) {
      display: none;
    }
  }

  @include part(overlay) {
    background-color: #fff;
    bottom: -9999px;
    left: -999px;
    opacity: 0%;
    pointer-events: none;
    position: fixed;
    right: -999px;
    top: -999px;
    transition: opacity $timing_fast ease-in-out;

    @include breakpoint(tablet) {
      display: none;
    }

    .navigation__checkbox:checked ~ & {
      opacity: 100%;
      pointer-events: auto;
      z-index: 100;
    }
  }

  @include part(overlay-toggle) {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @include part(overlay-toggle-text) {
    @include visuallyHidden;
  }

  @include part(toggle) {
    cursor: pointer;
    display: block;
    margin: 0; // Override legacy CSS
    position: relative;
    width: 32px; // Specify width to prevent toggle shifting
    z-index: 100;

    @include breakpoint(tablet) {
      display: none;
    }
  }

  @include part(toggle-icon) {
    left: 50%;
    transform: translateX(-50%);
    top: 4px;

    .navigation__checkbox:checked ~ .navigation__toggle & {
      background-color: transparent;
    }

    &,
    &::before,
    &::after {
      background-color: $clr_text;
      border-radius: 2px;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      transition: top $timing_fast ease-in-out, transform $timing_fast ease-in-out;
      width: 12px;
    }

    &::before {
      top: -4px;
      left: 0;

      .navigation__checkbox:checked ~ .navigation__toggle & {
        transform: rotate(45deg);
        top: 0;
      }
    }

    &::after {
      top: 4px;
      left: 0;

      .navigation__checkbox:checked ~ .navigation__toggle & {
        transform: rotate(-45deg);
        top: 0;
      }
    }
  }

  @include part(toggle-caption) {
    display: block;
    margin-top: 4px;
    padding-top: 16px;
  }

  @include part(toggle-text) {
    font-family: $font_familyHeading;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.7px;
    text-align: center;
    text-transform: uppercase;

    @include option(open) {
      display: block;

      .navigation__checkbox:checked ~ .navigation__toggle & {
        display: none;
      }
    }

    @include option(close) {
      display: none;

      .navigation__checkbox:checked ~ .navigation__toggle & {
        display: block;
      }
    }
  }

  @include part(items) {
    left: 0;
    opacity: 0%;
    margin: 8px 48px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50px;
    transform: translateY(8px);
    transform-origin: 50% 0;
    transition: opacity $timing_fast ease-in-out, transform $timing_fast ease-in-out;
    visibility: hidden;
    z-index: 100;

    @include breakpoint(tablet) {
      align-items: center;
      justify-content: space-between;
      margin: 0;
      position: static;
      transform: none;
      display: flex;
      opacity: 100%;
      pointer-events: auto;
      visibility: visible;
    }

    // When open/visible
    .navigation__checkbox:checked ~ & {
      cursor: pointer;
      opacity: 100%;
      pointer-events: auto;
      transform: translateY(0);
      visibility: visible;
    }
  }

  @include part(list) {
    list-style: none;
    margin: 0;
    padding: 0;

    @include breakpoint(tablet) {
      align-items: center;
      display: flex;
      margin-left: 12px;
    }

    @include breakpoint(laptop) {
      margin-left: 24px;
    }
  }

  @include part(list-item) {
    margin: 8px 0;

    @include breakpoint(tablet) {
      margin: 0 12px;
    }

    @include breakpoint(laptop) {
      margin: 0 16px;
    }

    @include option(hide-tablet) {
      @include breakpoint(tablet) {
        display: none;
      }

      @include breakpoint(laptop) {
        display: block;
      }
    }

    @include option(mobile-only) {
      @include breakpoint(tablet) {
        display: none;
      }
    }

    @include option(desktop-only) {
      display: none;

      @include breakpoint(tablet) {
        display: block;
      }
    }

    @include option(user-nav-item) {
      align-items: center;
      background-color: #eee;
      display: flex;
      transition: height $timing_fast ease-in-out;

      @include breakpoint(tablet) {
        height: $header-height-desktop;
        padding: 0 16px;
      }

      @include breakpoint(laptop) {
        padding: 0 24px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @include part(sub-nav) {
      animation-delay: 0.05s;
      background: white;
      border-radius: 0.75em;
      box-shadow: rgba(0, 0, 0, 33%) 0 0.5em 0.75em;
      display: none;
      margin-left: -0.75em;
      padding: 0.5em 0.75em;
      position: absolute;

      &.mobile-sub-menu {
        animation-delay: 0.05s;
        background: white;
        box-shadow: none;
        display: none;
        margin-left: 0.75em;
        padding: 0.5em 0.75em;
        position: relative;
      }
    }

    &:hover {
      .navigation__list-item__sub-nav {
        display: inline-block;

        &.mobile-sub-menu {
          display: block;
        }
      }
    }
  }

  @include part(item-link) {
    border-bottom: 1px solid #ededed;
    color: $clr_text;
    display: block;
    font-family: $font_familyHeading;
    font-size: 18px;
    font-weight: 600;
    padding: 8px 0;
    transition: border-bottom-color $timing_fast ease-in-out, color $timing_fast ease-in-out;

    &:hover,
    &:focus {
      color: $clr_brandPrimary;
      border-bottom-color: $clr_brandPrimary;
    }

    @include breakpoint(tablet) {
      border-bottom: none;
      font-size: 14px;
    }

    @include option(search) {
      cursor: pointer;
      margin-bottom: 0; // Override legacy style

      .icon {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }

  @include part(item-icon) {
    display: inline-block;
    vertical-align: middle;
    width: 16px;

    > svg {
      fill: currentcolor;
    }
  }

  @include part(secondary) {
    display: none;
    margin-top: 8px;

    @include breakpoint(tablet) {
      display: block;
      margin-top: 0;
    }
  }

  // Options ----------------------
}
