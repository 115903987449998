@import "base/helpers";

@include component(campaign-listing) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $grid-gutter-width: 30px !default;
  $grid-gutter-compensation: $grid-gutter-width * 0.5 * -1 !default;
  $timing_fast: 0.2s !default;

  // Component ----------------------

  // Parts ----------------------
  @include part(header) {
    align-items: top;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 2rem;

    @include breakpoint(phablet) {
      margin-bottom: 1.5rem;
      margin-top: 3rem;
    }
  }

  @include part(heading) {
    border-bottom: 3px solid #333;
    padding-bottom: 0.25rem;
  }

  @include part(heading-link) {
    color: #c3c3c3;
    font-family: $font_familyHeading;
    font-size: 0.875rem;
    transition: color $timing_fast ease-in-out;

    &:hover {
      color: $clr_brandPrimary;
    }

    @include breakpoint(tablet) {
      font-size: 1rem;
    }
  }

  @include part(list) {
    display: flex;
    flex-wrap: wrap;
    margin-left: $grid-gutter-compensation;
    margin-right: $grid-gutter-compensation;

    @include breakpoint(phablet) {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }

    > div {
      margin-top: -1px;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }

      @include breakpoint(phablet) {
        margin-bottom: 1.5rem;
        margin-top: 0;
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        width: calc(50% - 1.5rem);
      }

      @include breakpoint(tablet) {
        width: calc(33.33% - 1.5rem);
      }

      @include breakpoint(laptop) {
        width: calc(25% - 1.5rem);
      }
    }
  }

  // Option - narrow (on homepage) ----------------------
  @include option(narrow) {
    @include part(list) {
      > div {
        @include breakpoint(phablet) {
          width: calc(33.33% - 1.5rem);

          &:nth-last-child(1),
          &:nth-last-child(2),
          &:nth-last-child(3) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  // Option - single item in the list (e.g., featured campaign on homepage) ----------------------
  @include option(single-item) {
    @include part(list) {
      @include breakpoint(phablet) {
        margin-left: 0;
        margin-right: 0;
      }

      > div {
        width: 100%;

        @include breakpoint(phablet) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  // Option - row-2 (e.g., on Event landing page) ----------------------
  @include option(row-2) {
    @include part(list) {
      @include breakpoint(phablet) {
        justify-content: center;
      }

      > div {
        @include breakpoint(phablet) {
          width: calc(50% - 1.5rem);

          &:nth-last-child(1),
          &:nth-last-child(2) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  // Option - row-4 (e.g., on Eligibility landing page, instant search results) ----------------------
  @include option(row-4) {
    @include part(list) {
      @include breakpoint(phablet) {
        justify-content: center;
      }

      > div {
        @include breakpoint(phablet) {
          width: calc(50% - 3rem);

          &:nth-last-child(1),
          &:nth-last-child(2) {
            margin-bottom: 0;
          }
        }

        @include breakpoint(tablet) {
          width: calc(50% - 6rem);
        }

        @include breakpoint(laptop) {
          width: calc(25% - 1.5rem);

          &:nth-last-child(1),
          &:nth-last-child(2),
          &:nth-last-child(3),
          &:nth-last-child(4) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
