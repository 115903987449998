@import "base/helpers";

@include component(carousel) {
  // Variables ----------------------
  $clr_textInverse: #fff !default;
  $clr_text: #333 !default;
  $carousel-dot-size: 15px;
  $carousel-dot-size-mobile: 12px;
  $carousel-bigger-dot-size: 18px;
  $carousel-bigger-dot-size-mobile: 14px;

  // Component ----------------------

  // Scoped styles
  .slick-arrow {
    &::before {
      color: rgba(62, 62, 62, 48%);
      font-size: 28px;
    }
  }

  .slick-active {
    z-index: 1;
  }

  .slick-slide img {
    display: block;
    margin: auto;
    max-width: 100%;
  }

  // Parts ----------------------
  @include part(dots) {
    // Options ----------------------
    line-height: 1;
    margin-bottom: $carousel-dot-size-mobile * 1.5;
    margin-top: $carousel-dot-size-mobile * 0.5;
    position: relative;

    @include breakpoint(phablet) {
      margin-bottom: $carousel-dot-size * 1.5;
      margin-top: $carousel-dot-size * 0.5;
    }

    li {
      height: $carousel-dot-size-mobile * 1.5;
      width: $carousel-dot-size-mobile * 1.5;

      @include breakpoint(phablet) {
        height: $carousel-dot-size * 1.5;
        width: $carousel-dot-size * 1.5;
      }

      button {
        height: $carousel-dot-size-mobile * 1.5;
        padding: 0;
        width: $carousel-dot-size-mobile * 1.5;

        @include breakpoint(phablet) {
          height: $carousel-dot-size * 1.5;
          width: $carousel-dot-size * 1.5;
        }

        &::before {
          content: "";
          background-color: transparent;
          border: 2px solid $clr_textInverse;
          border-radius: 100%;
          box-sizing: border-box;
          height: $carousel-dot-size-mobile;
          left: $carousel-dot-size-mobile*0.25;
          opacity: 100%;
          position: absolute;
          top: $carousel-dot-size-mobile*0.25;
          transition: background-color 0.2s ease-in-out;
          width: $carousel-dot-size-mobile;

          @include breakpoint(phablet) {
            height: $carousel-dot-size;
            left: $carousel-dot-size*0.25;
            top: $carousel-dot-size*0.25;
            width: $carousel-dot-size;
          }
        }

        &:hover::before {
          background-color: transparentize($clr_textInverse, 0.85);
        }
      }

      &.slick-active button::before {
        content: "";
        background-color: $clr_textInverse;
        border: 2px solid $clr_textInverse;
        border-radius: 100%;
      }
    }

    @include option(bigger) {
      line-height: 1;
      margin-bottom: $carousel-bigger-dot-size-mobile * 1.5;
      margin-top: $carousel-bigger-dot-size-mobile * 0.5;
      position: relative;

      @include breakpoint(phablet) {
        margin-bottom: $carousel-bigger-dot-size * 1.5;
        margin-top: $carousel-bigger-dot-size * 0.5;
      }

      li {
        height: $carousel-bigger-dot-size-mobile * 1.5;
        width: $carousel-bigger-dot-size-mobile * 1.5;

        @include breakpoint(phablet) {
          height: $carousel-bigger-dot-size * 1.5;
          width: $carousel-bigger-dot-size * 1.5;
        }

        button {
          height: $carousel-bigger-dot-size-mobile * 1.5;
          padding: 0;
          width: $carousel-bigger-dot-size-mobile * 1.5;

          @include breakpoint(phablet) {
            height: $carousel-bigger-dot-size * 1.5;
            width: $carousel-bigger-dot-size * 1.5;
          }

          &::before {
            content: "";
            background-color: transparent;
            border: 2px solid $clr_textInverse;
            border-radius: 100%;
            box-sizing: border-box;
            height: $carousel-bigger-dot-size-mobile;
            left: $carousel-bigger-dot-size-mobile*0.25;
            opacity: 100%;
            position: absolute;
            top: $carousel-bigger-dot-size-mobile*0.25;
            transition: background-color 0.2s ease-in-out;
            width: $carousel-bigger-dot-size-mobile;

            @include breakpoint(phablet) {
              height: $carousel-bigger-dot-size;
              left: $carousel-bigger-dot-size*0.25;
              top: $carousel-bigger-dot-size*0.25;
              width: $carousel-bigger-dot-size;
            }
          }

          &:hover::before {
            background-color: transparentize($clr_textInverse, 0.85);
          }
        }

        &.slick-active button::before {
          content: "";
          background-color: $clr_textInverse;
          border: 2px solid $clr_textInverse;
          border-radius: 100%;
        }
      }
    }

    @include option(inverse) {
      li button::before {
        background-color: transparent;
        border-color: $clr_textInverse;
      }

      li button:hover::before {
        background-color: transparentize($clr_textInverse, 0.85);
      }

      li.slick-active button::before {
        background-color: $clr_textInverse;
        border-color: $clr_textInverse;
      }
    }

    @include option(dark) {
      li button::before {
        background-color: transparent;
        border-color: $clr_text;
      }

      li button:hover::before {
        background-color: transparentize($clr_text, 0.85);
      }

      li.slick-active button::before {
        background-color: $clr_text;
        border-color: $clr_text;
      }
    }
  }

  // Options ----------------------
  @include option(fade) {
    .slick-track {
      transform: none !important; // https://github.com/akiran/react-slick/issues/643
    }
  }
}
