@import "base/helpers";

@include component(article-card) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $font_familyCopy: "Open Sans", arial, sans-serif;
  $timing_fast: 0.2s !default;
  $timing_slow: 0.8s !default;

  // Component ----------------------
  align-items: stretch;
  background-color: #fff;
  border: 1px solid #eee;
  display: flex;
  width: 100%;

  @include breakpoint(phablet) {
    display: block;
  }

  // Parts ----------------------
  @include part(media) {
    align-items: stretch;
    display: flex;
    flex: 0 0 120px;
    overflow: hidden;

    @include breakpoint(phablet) {
      padding-bottom: 66.67%;
      position: relative;
    }
  }

  @include part(image) {
    background-position: center;
    background-size: cover;
    display: block;
    transform: scale(1);
    transition: transform $timing_slow ease-in-out;
    width: 100%;

    @include breakpoint(phablet) {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
    }

    .article-card:hover & {
      transform: scale(1.05);
    }
  }

  @include part(content) {
    padding: 0.5rem 1rem;
    width: auto;

    @include breakpoint(phablet) {
      padding: 1rem;
    }

    @include breakpoint(tablet) {
      padding: 2rem;
    }

    // Tweak padding for homepage
    .--narrow & {
      @include breakpoint(tablet) {
        padding: 1.5rem 1.25rem;
      }
    }
  }

  @include part(heading) {
    margin-bottom: 0.25rem;

    @include breakpoint(phablet) {
      margin-bottom: 0.5rem;
    }

    @include breakpoint(tablet) {
      margin-bottom: 0.75rem;
    }
  }

  @include part(heading-link) {
    color: $clr_text;
    transition: color $timing_fast ease-in-out;

    &:hover {
      color: $clr_brandPrimary;
    }
  }

  @include part(meta) {
    font-family: $font_familyHeading;
    font-size: 0.75rem;
    font-weight: 300;
    margin-top: 0.25rem;
    text-transform: uppercase;

    @include breakpoint(phablet) {
      margin: 0.5rem 0;
    }

    @include breakpoint(tablet) {
      font-size: 0.875rem;
    }
  }

  @include part(snippet) {
    display: none;

    @include breakpoint(phablet) {
      display: block;
      font-family: $font_familyHeading;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.4;
      margin-bottom: 0.5rem;
    }

    @include breakpoint(tablet) {
      font-size: 1rem;
    }
  }

  @include part(cta) {
    font-size: 0.75rem;
    text-transform: uppercase;
    transition: color $timing_fast ease-in-out;

    @include breakpoint(tablet) {
      font-size: 0.875rem;
    }

    &:hover {
      color: lighten($clr_brandPrimary, 1%);
    }
  }
}
