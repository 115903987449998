@import "base/helpers";

@include component(selling-point) {
  // Variables ----------------------
  $clr_textInverse: #fff !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $avatar-size-medium: 60px !default;
  $avatar-size-medium-desktop: 120px !default;

  // Component ----------------------

  // Parts ----------------------
  @include part(avatar) {
    display: block;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: $avatar-size-medium;

    @include breakpoint(tablet) {
      width: $avatar-size-medium-desktop;
      margin-bottom: 2rem;
    }
  }

  // Scoped styles
  @include part(copy) {
    color: $clr_textInverse;
    font-family: $font-family-primary;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.4;
    text-align: center;

    @include breakpoint(tablet) {
      font-size: 24px;
    }
  }

  @include part(cite) {
    margin-top: 1rem;
    text-align: center;
  }

  @include part(cta) {
    margin-top: 1rem;
    text-align: center;
  }

  // Options - isTestimonial ----------------------
  @include option(testimonial) {
    @include part(copy) {
      @include typography-size-5;
    }
  }
}
