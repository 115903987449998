@import "base/helpers";

@include component(text-heading) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $clr_textInverse: #fff !default;
  $clr_textSubdued: #aaa !default;
  $font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;

  // Component ----------------------
  @include typography-base;
  @include typography-bold;

  line-height: 1.16667;

  // Parts ----------------------
  // No parts

  // Options - align ----------------------
  @include option(align-left) {
    text-align: left;
  }

  @include option(align-center) {
    text-align: center;
  }

  @include option(align-right) {
    text-align: right;
  }

  // Options - margin ----------------------
  @include option(margin-bottom-none) {
    margin-bottom: 0;
  }

  @include option(margin-bottom-half) {
    margin-bottom: 0.5em;
  }

  @include option(margin-bottom-single) {
    margin-bottom: 1em;
  }

  @include option(margin-bottom-double) {
    margin-bottom: 2em;
  }

  @include option(margin-top-none) {
    margin-top: 0;
  }

  @include option(margin-top-half) {
    margin-top: 0.5em;
  }

  @include option(margin-top-single) {
    margin-top: 1em;
  }

  @include option(margin-top-double) {
    margin-top: 2em;
  }

  // Options - size ----------------------
  @include option(size-1) {
    @include typography-size-1;
  }

  @include option(size-2) {
    @include typography-size-2;
  }

  @include option(size-3) {
    @include typography-size-3;
  }

  @include option(size-4) {
    @include typography-size-4;
  }

  @include option(size-5) {
    @include typography-size-5;
  }

  @include option(size-6) {
    @include typography-size-6;
  }

  @include option(size-7) {
    @include typography-size-7;
  }

  @include option(size-8) {
    @include typography-size-8;
  }

  // Options - accent ----------------------
  @include option(accent) {
    &::before {
      content: "// ";
    }
  }

  // Options - light ----------------------
  @include option(light) {
    font-weight: 400;
    letter-spacing: 0.2px;
  }

  // Options - look ----------------------
  @include option(default) {
    color: $clr_text;
  }

  @include option(brand) {
    color: $clr_brandPrimary;
  }

  @include option(subdued) {
    color: $clr_textSubdued;
  }

  @include option(inverse) {
    color: $clr_textInverse;
  }

  // Options - uppercase ----------------------
  @include option(uppercase) {
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
