@use "sass:math";
@import "../../base/helpers";

@include component(footer) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_textInverse: #fff !default;
  $timing_default: 0.4s !default;
  $timing_fast: 0.2s !default;
  $font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $footer-bg: #414141;

  // Component ----------------------
  background-color: $footer-bg;
  clear: both; // Required due to legacy CSS
  line-height: 1;

  // Parts ----------------------

  @include part(container) {
    @include container;

    padding-bottom: 32px;
    padding-top: 32px;
    width: 100%;

    @include breakpoint(tablet) {
      padding-bottom: 48px;
      padding-top: 48px;
    }
  }

  @include part(primary) {
    margin-bottom: 32px;
  }

  @include part(links) {
    @include breakpoint(phablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @include part(links-group) {
    @include breakpoint(phablet) {
      padding-left: 8px;
      padding-right: 8px;
      width: 50%;

      &:nth-child(odd) {
        padding-left: 0;
      }

      &:nth-child(even) {
        padding-right: 0;
      }
    }

    @include breakpoint(tablet) {
      margin-bottom: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: 25%;

      &:nth-child(even),
      &:nth-child(odd) {
        padding-left: 16px;
        padding-right: 16px;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  @include part(group-item) {
    margin-bottom: 16px;

    @include breakpoint(phablet) {
      margin-bottom: 24px;
    }

    @include breakpoint(tablet) {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include part(toggle) {
    cursor: pointer;
    display: block;
    font-weight: 400; // Reset font-weight due to legacy CSS
    margin: 0;
    position: relative;

    @include breakpoint(phablet) {
      cursor: auto;
    }
  }

  @include part(toggle-icon) {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.82 1L7.94 6.88 2.06 1 1 2.06 7.94 9l6.94-6.94z' stroke-width='.5' stroke='%23414141' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
      content: "";
      display: block;
      height: 8px;
      position: absolute;
      right: 0;
      top: 4px;
      transform: rotate(0);
      width: 14px;

      .footer__list-checkbox:checked ~ .footer__toggle & {
        transform: rotate(-180deg);
      }
    }

    @include breakpoint(phablet) {
      display: none;
    }
  }

  @include part(list-checkbox) {
    opacity: 0%;
    position: absolute;
    visibility: hidden;

    @include breakpoint(tablet) {
      display: none;
    }
  }

  @include part(list-heading) {
    border-bottom: 1px solid #979797;
    color: $clr_textInverse;
    display: block;
    font-family: $font_familyHeading;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 12px;

    @include breakpoint(phablet) {
      border-bottom: 0;
      margin-bottom: 16px;
      padding-bottom: 0;
    }
  }

  @include part(list-content) {
    max-height: 0;
    opacity: 0%;
    overflow: hidden;

    .footer__list-checkbox:checked ~ & {
      max-height: 400px;
      opacity: 100%;
    }

    @include breakpoint(phablet) {
      opacity: 100%;
      max-height: none;
    }
  }

  @include part(list-links) {
    list-style: none;
    margin: 0;
    padding: 0;

    @include breakpoint(phablet) {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }

  @include part(list-item) {
    color: $clr_textInverse;
    font-family: $font-family-primary;
    font-size: 14px;
    line-height: math.div(18, 14);
    margin: 8px 0;
  }

  @include part(list-link) {
    color: $clr_textInverse;
    opacity: 100%;
    transition: opacity $timing_fast ease-in-out;

    &:hover,
    &:focus {
      color: $clr_textInverse;
      opacity: 80%;
    }
  }

  @include part(region) {
    margin-bottom: 32px;
    margin-top: 24px;

    @include breakpoint(phablet) {
      margin-top: 0;
      width: 33%;
    }

    @include breakpoint(tablet) {
      margin-bottom: 48px;
      margin-top: 24px;
      margin-left: calc(25% + 16px);
      width: calc(25% - 32px);
    }
  }

  @include part(region-label) {
    color: $clr_textInverse;
    display: inline-block;
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 400; // Reset font-weight due to legacy CSS
    margin: 0;
    padding-bottom: 12px;
  }

  @include part(region-select) {
    appearance: none;
    background-color: $footer-bg;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='9' viewBox='0 0 16 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.82 1L7.94 6.88 2.06 1 1 2.06 7.94 9l6.94-6.94z' stroke-width='.5' stroke='%23414141' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
    border: 1px solid #fafafa;
    border-radius: 32px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-family: $font-family-primary;
    font-size: 16px; // Increase font-size from 14px to 16px to prevent iOS zooming
    height: 32px;
    padding: 4px 16px;
    width: 100%;

    @include breakpoint(laptop) {
      font-size: 14px;
    }
  }

  @include part(social) {
    @include option(mobile) {
      @include breakpoint(phablet) {
        display: none;
      }
    }

    @include option(desktop) {
      display: none;

      @include breakpoint(phablet) {
        display: block;
      }
    }
  }

  @include part(secondary) {
    border-top: 1px solid #6a6a6a;
    padding-top: 8px;

    @include breakpoint(phablet) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
    }

    @include option(reduced) {
      border-top: 0;
      padding-top: 0;
    }
  }

  @include part(copyright) {
    color: $clr_textInverse;
    font-size: 12px;
    font-family: $font-family-primary;
    text-align: center;

    @include breakpoint(phablet) {
      flex: 1 0 33%;
      text-align: left;
    }
  }

  @include part(logo) {
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    width: 42px;

    @include breakpoint(phablet) {
      flex: 0 0 auto;
      margin: 0;
    }
  }

  @include part(logo-link) {
    display: block;
    opacity: 100%;
    transition: opacity $timing_fast ease-in-out;

    &:hover,
    &:focus {
      opacity: 80%;
    }
  }

  @include part(logo-svg) {
    display: block;
    fill: #fff;
    height: auto;
    width: 100%;
  }

  @include part(social) {
    @include breakpoint(phablet) {
      flex: 1 0 33%;
      text-align: right;
    }
  }

  @include part(social-list) {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;

    @include breakpoint(phablet) {
      justify-content: flex-end;
    }
  }

  @include part(social-item) {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @include part(social-link) {
    display: block;
    opacity: 100%;
    transition: opacity $timing_fast ease-in-out;

    &:hover {
      opacity: 80%;
    }
  }

  @include part(social-icon) {
    &::before {
      content: "";
      display: block;
    }

    @include option(email) {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.017.857l8.846 7.49L18.71.856H1.016zM0 1.69v10.717c0 .355.293.64.658.64h18.41c.365 0 .658-.285.658-.64V1.69l-9.43 7.99c-.25.21-.617.21-.864 0L0 1.69z' fill-rule='nonzero' fill='%23FFF'/%3E%3C/svg%3E");
        height: 14px;
        top: 4px;
        width: 20px;
      }
    }

    @include option(twitter) {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.178 2.263c-.726.336-1.505.563-2.323.665.835-.522 1.475-1.35 1.778-2.334-.784.484-1.65.835-2.57 1.025-.74-.823-1.79-1.334-2.955-1.334-2.234 0-4.046 1.89-4.046 4.22 0 .33.035.653.104.962-3.362-.177-6.344-1.857-8.34-4.41-.348.622-.547 1.347-.547 2.12 0 1.465.714 2.758 1.8 3.514-.664-.024-1.288-.214-1.834-.53v.053c0 2.044 1.395 3.75 3.245 4.14-.338.094-.695.146-1.065.146-.26 0-.514-.026-.762-.077.516 1.678 2.01 2.898 3.78 2.932-1.385 1.13-3.13 1.804-5.026 1.804-.326 0-.648-.022-.965-.06C2.242 16.3 4.37 17 6.655 17c7.444 0 11.513-6.432 11.513-12.01l-.014-.546c.795-.59 1.483-1.335 2.024-2.18z' fill-rule='nonzero' fill='%23FFF'/%3E%3C/svg%3E");
        height: 17px;
        top: 4px;
        width: 21px;
      }
    }

    @include option(facebook) {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.9 0H1.51C1.177 0 .905.274.905.612v15.49c0 .338.272.612.607.612h3.02v-6.48c0-.22-.176-.397-.394-.397h-.622c-.217 0-.394-.177-.394-.396V7.864c0-.22.177-.396.394-.396h.622c.218 0 .394-.177.394-.396V6.04c0-1.012.237-1.78.713-2.3.475-.52 1.257-.78 2.346-.78h1.487c.218 0 .394.178.394.397v1.577c0 .22-.175.397-.393.397h-.783c-.216 0-.39.02-.52.055-.128.036-.222.1-.28.196-.058.096-.096.192-.112.29-.017.096-.025.234-.025.412v.79c0 .218.176.395.394.395h1.344c.217 0 .375.177.352.394L9.28 9.444c-.023.218-.217.394-.435.394H7.75c-.217 0-.393.178-.393.397v6.48H16.9c.336 0 .607-.274.607-.61V.61c0-.338-.27-.612-.607-.612z' fill-rule='nonzero' fill='%23FFF'/%3E%3C/svg%3E");
        height: 17px;
        top: 4px;
        width: 18px;
      }
    }
  }

  @include part(social-caption) {
    @include visuallyHidden;
  }

  // Options ----------------------
}
