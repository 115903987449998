@import "csstyle/csstyle";
@import "./reset";
@import "./settings/variables";
@import "./overrides";

//@NOTE: This is for temp stuff only - don't let it grow :)
@import "./inbox";
@import "./_fonts";
@import "./_shares";

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  font-size: 16px;
}

body {
  padding: 0;
  font-family: "open sans", sans-serif;
  position: relative;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color: $clr_text;
}

p {
  line-height: 1.4;
}

a {
  text-decoration: none;
  color: #428bca;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-large {
  max-width: 1250px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.well {
  @include well($clr_lightMidGrey);
}
