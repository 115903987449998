@import "base/helpers";

@include component(page-block) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;

  // Component ----------------------

  padding-bottom: 2rem;
  padding-top: 2rem;
  width: 100%;

  @include part(slim) {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  @include breakpoint(phablet) {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  @include breakpoint(tablet) {
    padding-bottom: 4rem;
    padding-top: 4rem;

    @include part(slim) {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
    }
  }

  @include breakpoint(laptop) {
    padding-bottom: 5rem;
    padding-top: 5rem;

    @include part(slim) {
      padding-bottom: 1.75rem;
      padding-top: 1.75rem;
    }
  }

  @include part(desktop) {
    @include part(slim) {
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
  }

  // Parts ----------------------
  @include part(content) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    padding-left: 15px;
    padding-right: 15px;

    // Options - narrow ----------------------
    @include option(narrow) {
      @include container-narrow;
    }
  }

  // Options - look ----------------------
  @include option(default) {
    background-color: #fff;

    + .--default {
      padding-top: 0;
    }
  }

  @include option(light) {
    background-color: #f8f8f8;

    + .--light {
      padding-top: 0;
    }
  }

  &.--primary {
    background: linear-gradient(to right bottom, #3dc7ff, #0a6b92);
  }

  @include part(shares) {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f8f8f8;

    .shares-social {
      display: flex;
      justify-content: center;
    }
  }

  @include option(brand) {
    background-color: $clr_brandPrimary;

    + .--brand {
      padding-top: 0;
    }
  }

  @include option(brand-light) {
    background-color: lighten($clr_brandPrimary, 45%);

    + .--brand-light {
      padding-top: 0;
    }
  }

  // Options - size ----------------------
  @include option(short) {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;

    @include breakpoint(phablet) {
      padding-bottom: 2.25rem;

      // padding-top: 2.25rem;
    }

    @include breakpoint(tablet) {
      padding-bottom: 3rem;

      // padding-top: 3rem;
    }

    @include breakpoint(laptop) {
      padding-bottom: 3.75rem;

      // padding-top: 3.75rem;
    }
  }

  @include option(tall) {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;

    @include breakpoint(phablet) {
      padding-bottom: 3.75rem;
      padding-top: 3.75rem;
    }

    @include breakpoint(tablet) {
      padding-bottom: 5rem;
      padding-top: 5rem;
    }

    @include breakpoint(laptop) {
      padding-bottom: 6.25rem;
      padding-top: 6.25rem;
    }
  }

  // Options - padding ----------------------
  @include option(no-padding-bottom) {
    padding-bottom: 0;
  }

  @include option(no-padding-top) {
    padding-top: 0;
  }
}
