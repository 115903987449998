@import "base/helpers";

@include component(process-step) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $clr_textInverse: #fff !default;
  $font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $timing_fast: 0.2s !default;
  $icon-size-small: 70px;
  $icon-size-large: 100px;

  // Component ----------------------
  position: relative;
  vertical-align: top;

  @include breakpoint(tablet) {
    text-align: center;

    // Parts ----------------------
    // Separator
    &:not(:last-child) {
      &::before,
      &::after {
        background-color: #c3c3c3;
        border-radius: 3px;
        content: "";
        display: block;
        height: 3px;
        margin-top: $icon-size-large*0.25;
        position: absolute;
        width: 24px;
      }

      &::before {
        right: -16px;
        top: 19px;
        transform: rotate(45deg);
      }

      &::after {
        right: -16px;
        top: 34px;
        transform: rotate(-45deg);
      }
    }
  }

  @include breakpoint(xldesktop) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @include part(inner) {
    align-items: center;
    display: flex;

    @include breakpoint(tablet) {
      display: block;
    }
  }

  @include part(content) {
    padding-left: 1rem;

    @include breakpoint(tablet) {
      padding-left: 0;
      padding-top: 1rem;
    }
  }

  @include part(link) {
    color: $clr_text;

    .process-step:hover & {
      color: $clr_brandPrimary;
      transition: color $timing_fast ease-in-out;
    }
  }

  @include part(media-border) {
    background-color: #fff;
    border: 1px solid $clr_brandPrimary;
    border-radius: 50%;
    color: $clr_brandPrimary;
    display: inline-block;
    height: $icon-size-small;
    line-height: 1;
    margin-left: 0;
    margin-right: 0;
    transition: background-color $timing_fast ease-in-out, color $timing_fast ease-in-out;
    width: $icon-size-small;

    @include breakpoint(phablet) {
      height: $icon-size-large;
      width: $icon-size-large;
    }

    .process-step:hover & {
      background-color: $clr_brandPrimary;
      color: $clr_textInverse;
    }
  }

  @include part(icon) {
    border: 15px solid transparent;
    border-radius: 50%;
    width: 100%;

    @include breakpoint(phablet) {
      border-width: 20px;
    }

    > svg {
      fill: currentcolor;
      height: 38px; // Width & height needed for IE10/11
      width: 38px;

      @include breakpoint(phablet) {
        height: 58px;
        width: 58px;
      }
    }
  }

  @include part(number) {
    font-family: $font_familyHeading;
    font-size: $icon-size-small*0.5;
    font-weight: 300;
    line-height: $icon-size-small - 2px;
    text-align: center;
    width: $icon-size-small;

    @include breakpoint(phablet) {
      font-size: $icon-size-large*0.5;
      height: $icon-size-large;
      line-height: $icon-size-large - 2px;
      width: $icon-size-large;
    }
  }

  @include part(heading) {
    font-family: $font_familyHeading;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5em;
    transition: color $timing_fast ease-in-out;

    @include breakpoint(phablet) {
      font-size: 1rem;
    }

    .process-step:hover & {
      color: $clr_brandPrimary;
    }
  }

  @include part(copy) {
    font-family: $font_familyHeading;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.4;
    transition: color $timing_fast ease-in-out;

    @include breakpoint(phablet) {
      font-size: 1rem;
    }

    .process-step:hover & {
      color: $clr_brandPrimary;
    }
  }
}
