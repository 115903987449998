// Variables ----------------------
$clr_brandPrimary: #1fa8df !default;
$clr_text: #333 !default;
$clr_textInverse: #fff !default;
$clr_textSubdued: #aaa !default;
$font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
$font-family-v2: "Inter", sans-serif;

@mixin typography-light() {
  font-weight: 300;
}

@mixin typography-normal() {
  font-weight: 400;
}

@mixin typography-bold() {
  font-weight: 700;
}

@mixin typography-base() {
  color: $clr_text;
  display: block;
  font-family: $font-family-primary;
  margin: 0;
  padding: 0;
}

@mixin typography-size-1() {
  font-size: 2.75rem;
  letter-spacing: -0.025em;

  @include breakpoint(tablet) {
    font-size: 4.375rem;
  }
}

@mixin typography-size-2() {
  font-size: 2.25rem;
  letter-spacing: -0.025em;

  @include breakpoint(tablet) {
    font-size: 3.375rem;
  }
}

@mixin typography-size-3() {
  font-size: 2rem;
  letter-spacing: -0.025em;

  @include breakpoint(tablet) {
    font-size: 3rem;
  }
}

@mixin typography-size-4() {
  font-size: 1.5rem;

  @include breakpoint(tablet) {
    font-size: 2.25rem;
  }
}

@mixin typography-size-5() {
  font-size: 1.375rem;

  @include breakpoint(tablet) {
    font-size: 1.875rem;
  }
}

@mixin typography-size-6($usePx: false) {
  font-size: 1rem;

  @if $usePx ==true {
    font-size: 16px;
  }

  @include breakpoint(tablet) {
    font-size: 1.25rem;

    @if $usePx ==true {
      font-size: 20px;
    }
  }
}

@mixin typography-size-7($usePx: false) {
  font-size: 0.875rem;

  @if $usePx ==true {
    font-size: 14px;
  }

  @include breakpoint(tablet) {
    font-size: 1rem;

    @if $usePx ==true {
      font-size: 16px;
    }
  }
}

@mixin typography-size-8() {
  font-size: 0.75rem;

  @include breakpoint(tablet) {
    font-size: 0.875rem;
  }
}

@mixin typography-size-lede() {
  font-size: 1.125rem;
  letter-spacing: -0.02rem;

  @include breakpoint(tablet) {
    font-size: 1.5rem;
  }
}

/**** Version 2 ****/
@mixin typography-v2-base() {
  font-family: $font-family-v2;
}

@mixin typography-v2-regular() {
  font-weight: 400;
}

@mixin typography-v2-bold() {
  font-weight: 700;
}

@mixin typography-v2-extra-bold() {
  font-weight: 800;
}

@mixin typography-v2-size-1() {
  font-size: 3.2rem;
  line-height: 3.6rem;
  letter-spacing: 0.5px;

  @include breakpoint(tablet) {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}

@mixin typography-v2-size-2() {
  font-size: 2.8rem;
  line-height: 3.6rem;
}

@mixin typography-v2-size-3() {
  font-size: 2rem;
  line-height: 2.4rem;
}

@mixin typography-v2-size-4() {
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0;
}

@mixin typography-v2-size-5() {
  line-height: 1.6em;
  font-size: 1.4rem;
  letter-spacing: 0;
}

@mixin typography-v2-size-6() {
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0;
}

@mixin typography-v2-size-7() {
  font-size: 1.3rem;
  line-height: 1.2rem;
  letter-spacing: 0;
}

.--text-center,
.text-center {
  text-align: center;
}

.--text-left,
.text-left {
  text-align: left;
}

.--text-right,
.text-right {
  text-align: right;
}

h5 {
  font-size: 1.3rem;
}

h6 {
  font-size: 1rem;
  font-weight: 400;
}
