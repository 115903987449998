@import "../../base/helpers";

@include component(link-button) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_brandSecondary: #ca40a1 !default;
  $clr_brandTertiary: #ffb202 !default;
  $clr_text: #333 !default;
  $font_familyHeading: "Inter", "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $inputs_borderRadius: 4px !default;
  $inputs_borderWidth: 2px !default;
  $inputs_borderColor: #eee !default;
  $inputs_color: #000 !default;
  $button_primaryBackground: $clr_brandSecondary !default;
  $button_primaryBorder: transparent !default;
  $button_primaryColor: #fff !default;
  $button_primaryOutlineBackground: #fff !default;
  $button_primaryOutlineBorder: $clr_brandSecondary !default;
  $button_primaryOutlineColor: $clr_brandSecondary !default;
  $button_secondaryBackground: $clr_brandPrimary !default;
  $button_secondaryBorder: transparent !default;
  $button_secondaryColor: #fff !default;
  $button_defaultBorder: #fff !default;
  $button_secondaryOutlineBackground: #fff !default;
  $button_secondaryOutlineBorder: $clr_brandPrimary !default;
  $button_secondaryOutlineColor: $clr_brandPrimary !default;
  $button_tertiaryBackground: $clr_brandTertiary !default;
  $button_tertiaryBorder: transparent !default;
  $button_tertiaryColor: #fff !default;
  $button_tertiaryMutedBackground: lighten($clr_brandTertiary, 20%) !default;
  $button_tertiaryMutedBorder: transparent !default;
  $button_tertiaryMutedColor: $clr_text !default;
  $button_tertiaryOutlineBackground: #fff !default;
  $button_tertiaryOutlineBorder: $clr_brandTertiary !default;
  $button_tertiaryOutlineColor: $clr_brandTertiary !default;
  $button_plainBackground: #fff !default;
  $button_plainBorder: #ccc !default;
  $button_plainColor: $clr_text !default;
  $button_ghostBackground: transparent !default;
  $button_ghostBorder: #fff !default;
  $button_ghostColor: #fff !default;
  $button_payrequestBackground: #000 !default;
  $button_payrequestBorder: transparent !default;
  $button_payrequestColor: #fff !default;
  $button_paypalBackground: #fbc43a !default;
  $button_paypalBorder: transparent !default;
  $button_paypalColor: $clr_text !default;
  $button_facebookBackground: #3b5998 !default;
  $button_facebookBorder: transparent !default;
  $button_facebookColor: #fff !default;
  $timing_fast: 0.2s !default;
  $link-button-padding-horizontal: 32px !default;
  $link-button-padding-vertical: 12px !default;
  $link-button-font-size: 16px !default;

  // Component ----------------------
  background: transparent;
  border: $inputs_borderWidth solid $inputs_borderColor;
  border-radius: 100px;
  color: $inputs_color;
  cursor: pointer;
  display: inline-block;
  font-family: $font_familyHeading;
  font-size: $link-button-font-size * 0.875;
  font-weight: 600;
  line-height: 1.2em;
  padding: floor($link-button-padding-vertical * 0.875) $link-button-padding-horizontal * 0.875;
  text-align: center;
  transition: background-color $timing_fast ease-out, border $timing_fast ease-out, color $timing_fast ease-out;
  vertical-align: middle;

  @include breakpoint(tablet) {
    font-size: $link-button-font-size;
    padding: $link-button-padding-vertical $link-button-padding-horizontal;
  }

  &:not(.--disabled):hover,
  &:not(.--disabled):focus {
    background-color: #eee;
  }

  // Options - Look ----------------------

  @include option(primary) {
    color: $button_primaryColor;
    background-color: $button_primaryBackground;
    border-color: $button_primaryBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: #de40af;
    }
  }

  @include option(primary-outline) {
    color: $button_primaryOutlineColor;
    background-color: $button_primaryOutlineBackground;
    border-color: $button_primaryOutlineBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: $button_primaryOutlineBorder;
      color: #fff;
    }
  }

  @include option(plain-link) {
    color: $clr_brandPrimary;
    background-color: $button_ghostBackground;
    border: none;
    padding: 0;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: $button_ghostBackground;
      color: $clr_text;
    }
  }

  @include option(secondary) {
    color: $button_secondaryColor;
    background-color: $button_secondaryBackground;
    border-color: $button_secondaryBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: lighten($button_secondaryBackground, 5%);
    }
  }

  @include option(secondary-outline) {
    color: $button_secondaryOutlineColor;
    background-color: $button_secondaryOutlineBackground;
    border-color: $button_secondaryOutlineBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: $button_secondaryOutlineBorder;
      color: #fff;
    }
  }

  @include option(tertiary) {
    color: $button_tertiaryColor;
    background-color: $button_tertiaryBackground;
    border-color: $button_tertiaryBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: lighten($button_tertiaryBackground, 5%);
    }
  }

  @include option(tertiary-muted) {
    color: $button_tertiaryMutedColor;
    background-color: $button_tertiaryMutedBackground;
    border-color: $button_tertiaryMutedBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: lighten($button_tertiaryMutedBackground, 5%);
    }
  }

  @include option(tertiary-outline) {
    color: $button_tertiaryOutlineColor;
    background-color: $button_tertiaryOutlineBackground;
    border-color: $button_tertiaryOutlineBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: $button_tertiaryOutlineBorder;
      color: #fff;
    }
  }

  @include option(plain) {
    color: $button_plainColor;
    background-color: $button_plainBackground;
    border-color: $button_plainBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: transparentize($button_plainBorder, 0.7);
    }
  }

  @include option(ghost) {
    color: $button_ghostColor;
    background-color: $button_ghostBackground;
    border-color: $button_ghostBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: transparentize($button_ghostBorder, 0.7);
    }
  }

  @include option(icon-link) {
    color: $button_secondaryBackground;
    background-color: transparent;
    border-color: $button_plainBorder;
    display: flex;
    align-items: center;
    column-gap: 5px;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: transparent;
      color: lighten($button_secondaryBackground, 5%);
    }
  }

  @include option(icon-link-secondary) {
    color: $button_secondaryBackground;
    background-color: transparent;
    border-color: $button_defaultBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: transparent;
      color: lighten($button_secondaryBackground, 5%);
    }
  }

  @include option(payrequest) {
    color: $button_payrequestColor;
    background-color: $button_payrequestBackground;
    border-color: $button_payrequestBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: lighten($button_payrequestBackground, 5%);
    }
  }

  @include option(paypal) {
    color: $button_paypalColor;
    background-color: $button_paypalBackground;
    border-color: $button_paypalBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: lighten($button_paypalBackground, 5%);
    }

    // Logo style
    > i {
      font-family: Verdana, Tahoma, sans-serif;
      font-weight: bold;

      &:first-child {
        color: #253b80;
      }

      &:last-child {
        color: #179bd7;
      }
    }
  }

  @include option(facebook) {
    color: $button_facebookColor;
    background-color: $button_facebookBackground;
    border-color: $button_facebookBorder;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: lighten($button_facebookBackground, 5%);
    }
  }

  // Options - Bold ----------------------

  @include option(bold) {
    font-weight: 700;
  }

  // Options - Size ----------------------

  @include option(tiny) {
    font-size: $link-button-font-size * 0.66;
    padding: floor($link-button-padding-vertical * 0.25) floor($link-button-padding-horizontal * 0.25);

    @include breakpoint(tablet) {
      font-size: $link-button-font-size * 0.75;
      padding: floor($link-button-padding-vertical * 0.4) floor($link-button-padding-horizontal * 0.33);
    }
  }

  @include option(small) {
    font-size: $link-button-font-size * 0.75;
    padding: floor($link-button-padding-vertical * 0.5) floor($link-button-padding-horizontal * 0.5);

    @include breakpoint(tablet) {
      font-size: $link-button-font-size * 0.875;
      padding: floor($link-button-padding-vertical * 0.667) floor($link-button-padding-horizontal * 0.5);
    }
  }

  @include option(normal) {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.6em;
  }

  @include option(large) {
    font-size: $link-button-font-size * 1.125;
    padding: $link-button-padding-vertical * 1.25 $link-button-padding-horizontal * 1.5;
  }

  @include option(largev2) {
    @include typography-v2-size-3;
    @include typography-v2-extra-bold;
  }

  @include option(huge) {
    font-size: $link-button-font-size * 1.25;
    padding: $link-button-padding-vertical * 1.5 $link-button-padding-horizontal * 1.5;
  }

  // Options - Disabled ----------------------

  @include option(disabled) {
    cursor: not-allowed;
    opacity: 70%;
  }

  // Options - Flash Highlight ----------------------

  @include option(flash-highlight) {
    animation: link-button-pulse 1.6s 2;
    box-shadow: 0 0 0 rgba($clr_brandTertiary, 0.8);
  }

  // Options - Full width ----------------------

  @include option(full-width) {
    clear: both; // Floats from legacy styles can cause full width buttons to render strangely
    display: block;
    width: 100%;
  }

  // Options - Icon ----------------------

  @include option(icon) {
    min-width: 0;
    border: 0;
    padding: 0;

    &:not(.--disabled):hover,
    &:not(.--disabled):focus {
      background-color: transparent;
    }
  }

  // Options - Label ----------------------

  @include option(label) {
    border-radius: 8px;
    cursor: auto;
    pointer-events: none; // Disable hover styles
  }

  // Options - Loading ----------------------

  @include option(loading) {
    cursor: not-allowed;
    opacity: 70%;
    pointer-events: none;
  }

  // Options - Uppercase ----------------------

  @include option(uppercase) {
    text-transform: uppercase;
  }

  // Parts ----------------------
  // No parts
}

@keyframes link-button-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($clr_brandTertiary, 0.8);
  }

  70% {
    box-shadow: 0 0 0 10px rgba($clr_brandTertiary, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($clr_brandTertiary, 0);
  }
}
