@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0%;
  }

  1% {
    display: block;
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}
