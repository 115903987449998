@import "base/helpers";

@include component(home-hero) {
  // Variables ----------------------
  $clr_textInverse: #fff !default;
  $font_familyHeading: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $timing_fast: 0.2s !default;

  // Component ----------------------
  align-items: center;
  background-color: #000;
  background-position: center;
  background-size: cover;
  display: flex;
  min-height: 65vh;
  position: relative;

  // Parts ----------------------
  @include part(inner) {
    @include container-narrow;

    padding-bottom: 2rem;
    padding-top: 2rem;

    @include breakpoint(phablet) {
      padding-bottom: 3rem;
      padding-top: 3rem;
    }

    @include breakpoint(tablet) {
      padding-bottom: 4rem;
      padding-top: 4rem;
    }

    @include breakpoint(laptop) {
      padding-bottom: 5rem;
      padding-top: 5rem;
    }
  }

  @include part(heading) {
    color: $clr_textInverse;
    font-family: $font_familyHeading;
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: -0.1rem;
    line-height: 1.1;
    margin-bottom: 1rem;
    margin-top: 0;
    padding-right: 30%;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);

    @include breakpoint(phablet) {
      font-size: 4.375rem;
      margin-bottom: 2rem;
    }

    em {
      font-style: normal;
      text-decoration: underline;
    }
  }

  @include part(actions) {
    @include breakpoint(phablet) {
      display: flex;
    }
  }

  @include part(cta-primary) {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    @include breakpoint(phablet) {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }

  @include part(cta-secondary) {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    @include breakpoint(phablet) {
      margin-bottom: 1rem;
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }

  @include part(supporting) {
    // display: none;
    margin-top: 1rem;
    font-size: 0.8rem;

    &-header {
      color: white;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
    }

    @include breakpoint(phablet) {
      display: block;
      margin-top: 2rem;
      font-size: 1rem;
    }

    @include breakpoint(laptop) {
      margin-bottom: -2rem;
      margin-top: 4rem;
    }
  }

  @include part(caption) {
    bottom: 1rem;
    left: 1rem;
    line-height: 1;
    position: absolute;
    right: 1rem;
    text-align: right;

    @include breakpoint(laptop) {
      bottom: 2rem;
      left: 2rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 1680px;
      right: 2rem;
    }
  }

  @include part(caption-link) {
    color: $clr_textInverse;
    font-family: $font-family-primary;
    font-size: 0.75rem;
    opacity: 80%;
    transition: opacity $timing_fast ease-in-out;

    @include breakpoint(phablet) {
      font-size: 0.875rem;
    }

    &:hover,
    &:focus {
      opacity: 100%;
    }
  }
}
