// Overrides for 3rd party components
// NOTE: This should be used only if necessary


// React Tooltip ----------------------

$reactToolTip_infoColor_primary: #1FA8DF !default;
$reactToolTip_infoColor_secondary: #434343;

// info

.__react_component_tooltip.type-info {
    background-color: $reactToolTip_infoColor_primary !important;
}

.__react_component_tooltip.type-info.place-top:after {
    border-top-color: $reactToolTip_infoColor_primary !important;
}

.__react_component_tooltip.type-info.place-right:after {
    border-right-color: $reactToolTip_infoColor_primary !important;
}

.__react_component_tooltip.type-info.place-bottom:after {
    border-bottom-color: $reactToolTip_infoColor_primary !important;
}

.__react_component_tooltip.type-info.place-left:after {
    border-left-color: $reactToolTip_infoColor_primary !important;
}

// info helper secondary colour
.--secondary {
  .__react_component_tooltip.type-info {
    background-color: $reactToolTip_infoColor_secondary !important;
  }
}

.--secondary {
  .__react_component_tooltip.type-info.place-top:after {
    border-top-color: $reactToolTip_infoColor_secondary !important;
  }
}

.--secondary {
  .__react_component_tooltip.type-info.place-right:after {
    border-right-color: $reactToolTip_infoColor_secondary !important;
  }
}

.--secondary {
  .__react_component_tooltip.type-info.place-bottom:after {
    border-bottom-color: $reactToolTip_infoColor_secondary !important;
  }
}

.--secondary {
  .__react_component_tooltip.type-info.place-left:after {
    border-left-color: $reactToolTip_infoColor_secondary !important;
  }
}

// React Modal ----------------------

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

// React-Select ----------------------

// scroll-bar on scrollable items
// https://davidwalsh.name/osx-overflow
.Select-menu::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.Select-menu::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

// react-loading-skeleton ----------------------
@keyframes progress-alt {
  0% {
      opacity: 1;
  }
  50% {
      opacity: .6;
  }
}

.react-loading-skeleton {
  // Increasing specificity
  :root & {
    animation: progress-alt 1.2s ease-in-out infinite;
    background-color: #eee;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
  }
}

